import axios from 'axios';

const state = {
    productItems: []
}

const mutations = {
    UPDATE_PRODUCT_ITEMS (state, payload) {
        state.productItems = payload;
    },
    BLABLA (){

    }
}

const actions = {
    getProductItems ({ commit }) {
        axios.get(process.env.VUE_APP_API_URL +'/posts')
            .then((response) => {
                console.log(response);
                commit('UPDATE_PRODUCT_ITEMS', response.data);
            })
    },
    updateUpVote ({ commit }, payload ){
        const data = {
            'id': payload.id,
            'post_upvotes': payload.upvotes
        };

        axios.post(process.env.VUE_APP_API_URL + '/upvote/', data)
            .then((response) => {
                console.log(response);
            })

        commit('BLABLA', data);
    }
}

const getters = {
    productItems: state => state.productItems,
}

const productModule = {
    state,
    mutations,
    actions,
    getters
}

export default productModule;
