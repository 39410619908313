import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// Module importieren
import post from './modules/post';


export default new Vuex.Store({
  modules: {
    post
  }
})
