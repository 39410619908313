<template>
  <v-row dense>
    <v-col v-for="(productItem, index) in productItems"
           :key="index"
           cols="12"
    sm="12"
    md="6"
    lg="4">
      <postListItem :postListItem="productItem" :index="index" />
    </v-col>
  </v-row>
</template>

<script>
import postListItem from "@/components/post/postListItem";

export default {
  name: 'postList',
  components:{
    postListItem
  },
  computed: {
    productItems() {
      return this.$store.getters.productItems;
    }
  }
}
</script>
