<template>
  <div class="home">
    <v-container fluid>
        <Postlist/>
    </v-container>

    </div>
</template>

<script>
// @ is an alias to /src
import Postlist from '@/components/post/postList'

const entries = [
  {
    id: 1,
    title: 'Spaghetti Bolognese',
    desc: 'Ein Nudelgericht <strong>mit</strong> Hackfleischsauce',
    upvotes: 16,
    downvotes: 0,
    author: 'italo',
    image: 'https://via.placeholder.com/64/64',
    flex: 12
  },
  {
    id: 2,
    title: 'Spaghetti Milano',
    desc: 'Ein Nudelgericht mit Tomatensauce',
    upvotes: 16,
    downvotes: 0,
    author: 'italo',
    image: 'https://via.placeholder.com/64/64',
    flex: 6
  },
  {
    id: 3,
    title: 'Peking Ente',
    desc: 'eine chinesische spezialität',
    upvotes: 45,
    downvotes: 0,
    author: 'marco',
    image: 'https://via.placeholder.com/64/64',
    flex: 3
  },
  {
    id: 4,
    title: 'schnitzel ',
    desc: 'Ein wiener schnitzel',
    upvotes: 23,
    downvotes: 0,
    author: 'Österreich',
    image: 'https://via.placeholder.com/64/64',
    flex: 8
  }
];

export default {
  name: 'Home',
  components: {
    Postlist
  },
  data: () => ({
    entries: entries,
    namesArray: []
  }),
  created() {
    this.$store.dispatch('getProductItems');
  }
}
</script>
