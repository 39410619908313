<template>
  <v-card
      class=" ma-4 rounded-br-xl"
      elevation="2"

  >
    <v-img
        height="250px"
        :src="previewImageSource"
    >

     </v-img>
    <v-card-title class="mt-1">{{postListItem.post_title}}</v-card-title>

    <v-card-subtitle v-if="postListItem.post_subtitle" class=" subtitle-1">
      {{ postListItem.id }}
    </v-card-subtitle>
    <v-card-text>
      <div v-html="postListItem.post_intro"></div>
    </v-card-text>

    <v-card-actions>
      <v-row justify="space-between" align="center">
        <v-col><v-btn
            color="orange"
            text
        >
          Weiterlesen
        </v-btn>
        </v-col>
        <v-col class="text-center">
          <v-row align="center">
            <v-col>
              <v-btn
                  icon
                  color="blue lighten-2"
                  @click="upVote"
              >
                <v-icon>mdi-thumb-up</v-icon>
              </v-btn>
              {{postListItem.post_upvotes}}
              <v-btn
                  icon
                  color="blue lighten-2"
                  @click="downVote"
              >
                <v-icon>mdi-thumb-down</v-icon>
              </v-btn>
              {{postListItem.post_downvotes}}
            </v-col>

          </v-row>

        </v-col>
      </v-row>



    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'postListItem',
  props: ['postListItem', 'index'],
  data() {
    return {
      show: false,
      assetUrl: process.env.VUE_APP_ASSET_URL
    }
  },
  methods:{
    upVote () {
      this.postListItem.post_upvotes++;
      this.$store.dispatch('updateUpVote', {
        'id' : this.postListItem.id,
        'upvotes' : this.postListItem.post_upvotes
      }
      )
    },
    downVote () {
     this.postListItem.post_downvotes++;
    }
  },
  computed:{
    previewImageSource(){
      return this.postListItem.post_randomimage ? 'https://picsum.photos/60'+this.index : this.assetUrl + this.postListItem.post_previewimage;
    },
    votesDifference(){
      return this.postListItem.post_upvotes - this.postListItem.post_downvotes;
    }
  }
}
</script>
<style scoped>
.test{
  background: rgba(0,0,0,0.5);
}
</style>
